'use strict';

/* Filters */

angular.module('wt.filters', []).
  filter('utc_to_local', function() {
    return function(utc) {
      utc = utc || '';
      // String should be RFC2822 or ISO 8601
      var d = new Date(utc.split(' ').join('T') + 'Z');
      return d.toLocaleString();
    }
  })

  .filter('interpolate', ['version', function(version) {
    return function(text) {
    return String(text).replace(/\%VERSION\%/mg, version);
    }
  }])
  

  .filter("trim", function(){
    return function(text){
      return text.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    }
  })

  .filter("ucwords", function(){
    return function(str){
      str = str || '';
      return (str.toLowerCase() + '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
        return $1.toUpperCase();
      });
    }
  })
 

  .filter("not_empty", function(){
    return function(text){
        return (text) ? text : '-';
    };
  })

  // removes the 'directory' object from the array
  // any object that haves a 0 bytes size
  // that also does not have a '/' in it's name
  .filter("s3_list", function(){
    return function(list){
      for (var row in list) {
       if (list[row]['size'] == 0 && list[row]['name'].indexOf("/") != -1)
          delete list[row];
      }
      return list;
    };
  })

  .filter("s3_get_file_name", function(){
    return function(s3filename){
        // sometimes the function is called without any files
        s3filename = (typeof s3filename == "undefined") ? '' : s3filename;
        // return the last element of the array
        return s3filename.split("/").pop();
    };
  })

  // gets the file extension from a string
  // if no file extension is found, it returns the string as-is
  .filter("file_extension", function(){
    return function(file_name){
        // sometimes the function is called without any parameter
        file_name = (typeof file_name == "undefined") ? '' : file_name;
        // return the last element of the array
        return file_name.split(".").pop();
    };
  })

  // returns a glyphicon class according to the extension
  // it would have been much easier with the glyphicons file types icons (cost 20dlls)
  .filter("ext_2_icon", function(){
    return function(ext) {

      // sometimes there is no extension
      ext = (typeof ext == "undefined") ? 'undefined' : ext;
      // sometimes it's uppercase
      ext = ext.toLowerCase();

      var ico = 'file';
      var types  =['image', 'video', 'archive', 'audio', 'list'];

      types['image'] = ['png','jpg','jpeg','tiff','raw','gif','psd','ai','webp','svg','bmp','dib','jpe'];
      types['video'] = ['flv','avi','mov','mp4','mpg','wmv','3gp','asf','rm','swf','mov','aaf','dat','fla','m4v','mkv','mpeg','mpe','ogg','webm'];
      types['archive'] = ['7z','rar','zip','tar','gz','bz2','cab','deb','apk','dmg','jar','tar.gz'];
      types['audio'] = ['wav','mp3','wma','mp2','ma4','mp4','m4p','acc','swa'];
      types['list'] = ['csv','xls','xlsx','xlr','txt','numbers','gsheet','tab','tvs','db'];

      switch (true)
      {
        case (types['image'].indexOf(ext) > -1):
        ico = 'picture';
        break;

        case (types['video'].indexOf(ext) > -1):
        ico = 'film';
        break;

        case (types['archive'].indexOf(ext) > -1):
        ico = 'compressed';
        break;

        case (types['audio'].indexOf(ext) > -1):
        ico = 'music';
        break;

        case (types['list'].indexOf(ext) > -1):
        ico = 'list';
        break;

        default:
        // icon by default is 'file'
        break;
      }
      // return the icon class
      return 'glyphicon glyphicon-' + ico;
    };
  })

  .filter("s3_get_folder", function(){
    return function(s3filename){
        return s3filename.split("/")[0];
    };
  })

  .filter("s3_make_link", function(){
    return function(file){
        return 'https://s3.amazonaws.com/webtools_sharing/' + file;
    };
  })

  .filter("ok_remove", function(){
    return function(yes_no){
        return (yes_no) ? 'glyphicon glyphicon-ok' : 'glyphicon glyphicon-remove';
    };
  })

  .filter("timestamp_to_date", function () {
    return function(timestamp) {
      var a = new Date(timestamp*1000);
      var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();
      var sec = a.getSeconds();
      var time = date+', '+month+' '+year+' '+hour+':'+min+':'+sec ;
      return time;
    };
  })

    .filter("clean_timestamp", function () {
    return function(time) {
      var date = new Date(time.split(' ').join('T') + 'Z');
      return date.toLocaleString();
    };
  })

    .filter("money", function () {
    return function(money) {
      var out = (typeof money == "undefined") ? 0 : money;

      // if it's already formatted, return it!
        if (typeof out ==  'string' && out.indexOf('$') == 0)
          return out;

        out = (money == null) ? 0 : money;
        out = parseFloat(out);
      return out.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    };
  })

  .filter("s3_file_size", function(){
    return function(bytes){
      switch (true)
      {
        case (bytes < 1024): // bytes
          return bytes + ' B';

        case (bytes < Math.pow(1024,2)): // kilobytes
          return Math.round((bytes / 1024) * 100) / 100 + ' kB';

        case (bytes < Math.pow(1024,3)): // megabytes
          return Math.round((bytes / Math.pow(1024,2)) * 100) / 100 + ' MB';

        case (bytes < Math.pow(1024,4)): // gigabytes
          return Math.round((bytes / Math.pow(1024,3)) * 100) / 100/ 100 + ' GB';

        case (bytes < Math.pow(1024,5)): // terabytes
          return Math.round((bytes / Math.pow(1024,4)) * 100) / 100 + ' TB';

        case (bytes < Math.pow(1024,6)): // petabytes
          return Math.round((bytes / Math.pow(1024,5)) * 100) / 100 + ' PB';

        case (bytes < Math.pow(1024,7)): // exabytes
          return Math.round((bytes / Math.pow(1024,6)) * 100) / 100 + ' EB';

        case (bytes < Math.pow(1024,8)): // zettabytes
          return Math.round((bytes / Math.pow(1024,7)) * 100) / 100 + ' ZB';
          // TODO: add support for yotta, xona, weka, vunda, uda, treda, sorta, rinta, quexa, pepta, ocha, nena, minga, luma 
      }
    };
  })

  .filter("format_phone", function(){
  return function (phone) {
  phone = (phone === null)? '' : phone;
  // remove all non numeric
  phone = phone.replace(/[^0-9]+/g, '');

  switch (phone.length)
  {
    case 10:
    // mach 6641254900 to 664-125-4900
    phone = phone.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3');
    break;

    case 11:
    // match 16199428191 to +1 619-428-9191
    phone = phone.replace(/[1](\d{3})(\d{3})(\d{4})/g, '+1 $1-$2-$3');
    break;

    case 12:
    // match 526641254900 to +52 664-125-4900
    phone = phone.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/g, '+$1 $2-$3-$4');
    break;

    case 13:
    // match 5216641254900 to +521 664-125-4900
    phone = phone.replace(/(\d{3})(\d{3})(\d{3})(\d{4})/g, '+$1 $2-$3-$4');
    break;

    default:
    // Debug feature
    // console.log("Invalid: " + phone);
    // phone = ("Invalid: " + phone);
    break;
  }

  return phone;
};
});