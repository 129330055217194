angular.module('wt.membersModule', [])
.controller('members_page', ['$scope', '$http', '$timeout', 'promiseTracker', 'authService', function ($scope, $http, $timeout, promiseTracker, authService)
{
	$scope.$on('event:auth-loginRequired', function() {
		$('#webtools-auth-dlg').modal('show');
	});


	$scope.memberLoading = promiseTracker('members');

	$scope.open_sale = function (sale_id) {
		if(isJFX()) {
			JFX.open_in_browser(base_url + "reports/sale/" +sale_id + "?authtoken=" + getAuthToken());
		} else {
			window.open(base_url + "reports/sale/"+sale_id);
		}
	};

	$scope.get_latest_members = function() {
		$scope.loading_latest_members = true;
		$http.get(base_url + 'members/read/latest/10', {tracker: 'members'})
		.success(function(data) {
			delete $scope.members;
			$scope.members = data;
		}).then(function(){
			$scope.loading_latest_members = false;
		});
	};

	$scope.create_customer = function() {
		$('#create_member').modal('show');
		$scope.new_membership = {
			customer_id:"",
			first_name:"",
			last_name:"",
			email:"",
			phone_number:"",
			membership_id:"",
			business_name:"",
			resale_number:""
		};
	};

	$scope.submit_customer = function() {
		$('#create_customer_btn').button('loading');
		// submit fields
		$http.post(base_url + 'members/create', $scope.new_membership).success(function (res) {
			//console.log(res);
			if (res.status == "success") {
				alert_message(res.message, "success");

				// close modal
				$('#create_member').modal('hide');

			} else {
				alert_message(res.message, "danger");
			}
		}).then(function(){
			$('#create_customer_btn').button('reset');

			// reload the members list
			$scope.get_latest_members();
		});
		
	};

	$scope.$watch('search_by_card_id', function (card_id) {
		// if not empty string
		if (card_id)
		{
			$scope.isSearching = true;
			var encoded_card_id = encodeURIComponent(card_id);
			setTimeout(function() {
				// if the text has not changed, search =]
				if (card_id === $scope.search_by_card_id) {
					$http.get(base_url + 'members/read/search/' + encoded_card_id)
					.success(function(data) {
						// if it's empty show the no results alert	
						$scope.no_results_found = jQuery.isEmptyObject(data);
						
						// delete previous data
						delete $scope.members;

						$scope.members = data;
						// finished searching
						$scope.isSearching = false;
					});
				}
			}, 500);
		} else {
			// since we don't have anything to show:
			$scope.get_latest_members();
			
			// not even searching
			$scope.isSearching = false;
		}
	});


	$http.get(base_url + 'members/get/total_members').success(function(data) {
		$scope.statusSubtitle = "has " + data + " members";
	});

	// open the member details dialog
	$scope.member_details = function(member) {
		$scope.selectedMember = member;
		$('#member_details_dlg').modal('show');
	};


	// save the member
	$scope.update_member = function() {
        $('#update_customer_btn').button('loading');
		$http.post(base_url + 'members/update', $scope.selectedMember).success(function (res) {
		    if (res.status == "success") {
                alert_message(res.message, "success");

                // close modal
                $('#member_details_dlg').modal('hide');
            } else {
                alert_message(res.message, "danger");
            }
        }).then(function () {
            $('#update_customer_btn').button('reset');

            // reload the members list
            $scope.get_latest_members();
        });
	};

	$scope.open_sales = function ($event, member) {
		$event.stopPropagation();
		if (member.member_id != null) {
			$scope.loading_member_sales = true;
			$scope.member_sales_list = {};

			alert_message("Opening customer report file.", "success");

			$scope.current_member = member;

			// get member details
			$http.get(base_url + 'reports/member_information/' + member.member_id)
			.success(function(res) {
				$scope.current_member.data = res;
			});
			
			// open modal
			$('#member_sales_list_modal').modal('show');

			// get transaction data
			$http.get(base_url + 'reports/member_sales_list/' + member.member_id)
			.success(function(res) {
				$scope.member_sales_list = res;
			}).then(function(){
				$scope.loading_member_sales = false;
			});


		} else {
			alert_message("Member ID not found!.", "danger");
		}
	}

}]);