angular.module('wt.shareModule', [])

.controller('share_page', ['$scope', '$http', '$timeout', '$filter', 'promiseTracker', 'authService', function ($scope, $http, $timeout, $filter, promiseTracker, authService)
{
	$scope.$on('event:auth-loginRequired', function() {
		$('#webtools-auth-dlg').modal('show');
	});

	$scope.update_share_subtitle = function () {
			$http.get(base_url + 'share/get/file_size_aggregate').success(function(data) {
			var size_string = $filter('s3_file_size')(data);
			$scope.statusSubtitle = "has used " + size_string + " of space";
		});
	};
	// call as soon as the page loads:
	$scope.update_share_subtitle();

	$scope.shareLoading = promiseTracker('shareLoading');

	$scope.get_file_list = function () {
		$http.get(base_url + 'share/get/index', {tracker: 'shareLoading'})
		.success(function(data) {
			// delete the prior
			delete $scope.files;
			// fill with the new
			$scope.files = data;
		});
	};

	$scope.file_details = function(file) {
		delete $scope.selectedFile;
		$scope.selectedFile = file;
		$('#file_details_dlg').modal('show');
	};


	$scope.upload_file_dlg = function() {
		$('#file_upload_dlg').modal('show');
	};

	$scope.upload_file = function() {
		alert('uploading');
	};

	$scope.delete_file = function (file) {
		// set button status to deletion in progress
		$('#delete_file_btn').button('loading');

		var perform_delete = function (file)
		{
			$http.delete(base_url + 'share/delete/'+ file.id)
			.success(function(data) {
				if (data.status == "success")
				{
					// delete the currently selected file from the DOM
					delete $scope.selectedFile;
					// refresh list
					$scope.get_file_list();
					// close current modal
					$('#file_details_dlg').modal('hide');
					$('#delete_file_btn').button('reset');
					// show confirmation
					alert_message(data.msg, "success");
				}
				else
				{
					$('#delete_file_btn').button('reset');
					// show error
					alert_message(data.msg, "danger");
				}
			});
		};

		var cancel_delete = function()
		{
			$('#delete_file_btn').button('reset');
		};

		confirm_dialog("Are you sure you want to delete: " + file.file_name + "?", "Please Confirm", perform_delete, file, cancel_delete);

	};

	// call as soon as the page loads: 
	$scope.get_file_list();


}]);