angular.module('wt.reportsModule', [])

.controller('reportsCtrl', ['$scope', '$http', '$timeout', '$filter', 'promiseTracker', 'authService', function ($scope, $http, $timeout, $filter, promiseTracker, authService)
{
	$scope.$on('event:auth-loginRequired', function() {
		$('#webtools-auth-dlg').modal('show');
	});

	$scope.reports, $scope.aggregate = {};
	$scope.pending_eod = false;
	$('#dwnldCSV').tooltip({container:'body'});

	var load_reports = function () {
		$http.get(base_url + 'reports/latest_sales_list').success(function (data) {
			$scope.reports = data;
		});
	};

	var load_sales_aggregates = function () {
		$http.get(base_url + 'reports/sales_aggregates').success(function (data) {
			$scope.aggregate = data;
		});
	};

	$scope.$watchCollection('[search_sales, pending_eod]', function (inputArr) {
		var search_str = inputArr[0];
		var pending_eod = inputArr[1];
		// if not empty string
		if (search_str || pending_eod)
		{
			$scope.isSearching = true;
			var encoded_search_str = encodeURIComponent(search_str);
			encoded_search_str = (encoded_search_str == 'undefined') ? '' : encoded_search_str;
			setTimeout(function() {
				// if the text has not changed, search =]
				if (search_str === $scope.search_sales) {
					var url = base_url + 'reports/search_sales/' + encoded_search_str;
					url = (pending_eod == true) ? url + '?pending_eod=true' : url;

					$http.get(url)
					.success(function(data) {
						// if it's empty show the no results alert	
						$scope.no_results_found = jQuery.isEmptyObject(data);
						
						// delete previous data
						$scope.reports = {};

						$scope.reports = data;

					}).then(function(){
						// finished searching
						$scope.isSearching = false;
					});
				}
			}, 500);

		} else {
			// since we don't have anything to show:
			load_reports();
			
			// not even searching
			$scope.isSearching = false;
		}
	});

	$scope.open_sale = function (sale_id) {
		// blocking request (getAuthToken())
		if(isJFX()) {
			JFX.open_in_browser(base_url + "reports/sale/" +sale_id + "?authtoken=" + getAuthToken());
		} else {
			window.open(base_url + "reports/sale/"+sale_id);
		}
	};

	$scope.open_user = function ($event, member) {
		$event.stopPropagation();
		if (member.member_id != null) {
			$scope.loading_member_sales = true;
			$scope.member_sales_list = {};

			alert_message("Opening customer report file.", "success");

			$scope.current_member = member;

			// get member details
			$http.get(base_url + 'reports/member_information/' + member.member_id)
			.success(function(res) {
				$scope.current_member.data = res;
			});
			
			// open modal
			$('#member_sales_list_modal').modal('show');

			// get transaction data
			$http.get(base_url + 'reports/member_sales_list/' + member.member_id)
			.success(function(res) {
				$scope.member_sales_list = res;
			}).then(function(){
				$scope.loading_member_sales = false;
			});


		} else {
			alert_message("No customer assigned to this sale.", "danger");
		}
	}

	// fire on load!
	load_reports();
	load_sales_aggregates();
	//window.setInterval(load_reports, 10000);
}]);