angular.module('wt.userReportsModule', [])

.controller('userReportsCtrl', ['$scope', '$http', '$timeout', '$filter', function ($scope, $http, $timeout, $filter)
{
	$scope.reports = {};

	$scope.load_reports = function () {
		$http.get(base_url + 'reports/user_report_list').success(function (data) {
			$scope.reports = data;
		});
	};

	// fire on load!
	$scope.load_reports();
	
}]);