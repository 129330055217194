angular.module('wt.settingsModule', [])

.controller('settingsCtrl',['$scope','$http','authService', function($scope, $http, authService){
	$scope.$on('event:auth-loginRequired', function() {
		$('#webtools-auth-dlg').modal('show');
	});

	$scope.locations = {};
	$scope.registers = {};
	$scope.users = {};


	$scope.editLocation = function(location) {
		$('#edit_location_dlg').modal('show');
		$scope.location_m = location;
	};

	$scope.saveLocation = function(location) {
		var url = base_url + 'settings/locations/';
		url = (location.location_id == false) ? url + 'create' : url + 'update';

		$http.post(url, location).success(function (res) {
			console.log(res);
		}).then(function(){
			$('#edit_location_dlg').modal('hide');
			$scope.init();
		});
	};

	$scope.editRegister = function(register) {
		$('#edit_register_dlg').modal('show');
		$scope.register_m = register;
	};

	$scope.saveRegister = function(register) {
		var url = base_url + 'settings/registers/';
		url = (register.register_id == false) ? url + 'create' : url + 'update';

		$http.post(url, register).success(function (res) {
			console.log(res);
		}).then(function(){
			$('#edit_register_dlg').modal('hide');
			$scope.init();
		});
	};

	$scope.editUser = function(user) {
		$('#edit_user_dlg').modal('show');
		$scope.user_m = user;
		if ($scope.user_m.isnew == true) {
			$scope.user_m.isnew = true;
		} else {
			$scope.user_m.isnew = false;
		}
	};

	$scope.deleteUser = function(username_login) {
		$('#edit_user_dlg').modal('hide');
		confirm_dialog("you want to delete: " + username_login, "Are you sure?", function(id){
			$http.get(base_url + 'settings/users/delete/'+id).success(function(res){
				console.log(res);
				$scope.init();
			});
		}, username_login, null, null);
	};
	$scope.deleteRegister = function(register_id) {
		$('#edit_register_dlg').modal('hide');
		confirm_dialog("you want to delete: " + id, "Are you sure?", function(id){
			$http.get(base_url + 'settings/registers/delete/'+id).success(function(res){
				console.log(res);
				$scope.init();
			});
		}, register_id, null, null);
	};
	$scope.deleteLocation = function(location_id) {
		$('#edit_location_dlg').modal('hide');
		confirm_dialog("you want to delete: " + id, "Are you sure?", function(id){
			$http.get(base_url + 'settings/locations/delete/'+id).success(function(res){
				console.log(res);
				$scope.init();
			});
		}, location_id, null, null);
	};

	$scope.saveUser = function(user) {
		var url = base_url + 'settings/users/';
		url = (user.isnew == true) ? url + 'create' : url + 'update';
		delete user.isnew;
		$http.post(url, user).success(function (res) {
			console.log(res);
		}).then(function(){
			$('#edit_user_dlg').modal('hide');
			$scope.init();
		});
	};


	var loadLocations = function () {
		$http.get(base_url + 'settings/locations').success(function (data) {
			$scope.locations = data;
		});
	};

	var loadRegisters = function () {
		$http.get(base_url + 'settings/registers').success(function (data) {
			$scope.registers = data;
		});
	};

	var loadUsers = function () {
		$http.get(base_url + 'settings/users').success(function (data) {
			$scope.users = data;
		});
	};

	$scope.init = function() {
		loadLocations();
		loadRegisters();
		loadUsers();
	};

	$scope.init();

}]);