angular.module('wt.inventoryModule', [])



.controller('inventory_page', ['$scope', '$http', '$timeout', 'promiseTracker', 'authService', function ($scope, $http, $timeout, promiseTracker, authService)
{
	$scope.$on('event:auth-loginRequired', function() {
		$('#webtools-auth-dlg').modal('show');
	});

	$scope.update_inventory_subtitle = function () {
			$http.get(base_url + 'inventory/get/total_products').success(function(data) {
			$scope.statusSubtitle = "has " + data + " products";
		});
	};
	// call as soon as the page loads:
	$scope.update_inventory_subtitle();

	$scope.loading_inventory_bars = promiseTracker('products');

	$scope.get_latest_inventory = function () {
		$http.get(base_url + 'inventory/read/latest/10', {tracker: 'products'})
		.success(function(data) {
			// delete the prior
			delete $scope.products;
			// fill with the new
			$scope.products = data;
		});
	};

	// call as soon as the page loads:
	$scope.get_latest_inventory();

	// open the product details dialog
	$scope.product_details = function(product) {
		$scope.selectedProduct = product;
		$('#product_details_dlg').modal('show');
	};

	$scope.product_qtys = function($event, product) {
		// ng-click is nested:
		$event.stopPropagation();
		// delete any previous info if any:
		delete $scope.productqtys;
		// show the loading bars:
		$scope.isLoadingQtys = true;
		var encoded_product_id = encodeURIComponent(product.product_id);
		$http.get(base_url+'inventory/read/qtys/'+encoded_product_id)
		.success(function(data) {
			$scope.productqtys = data;
			$scope.isLoadingQtys = false;
		});

		$scope.selectedProduct = product;
		$('#qty_details_dlg').modal('show');
	};


	$scope.add_product_qty = function(input) {
		// if empty, initialize as array
		$scope.c_qty = ($scope.c_qty) ? $scope.c_qty : [];
		$scope.c_qty.push(input);
		console.log($scope.c_qty);
	};


	// save the product
	$scope.update_product = function() {
		var response = $http.post(base_url + 'inventory/update', $scope.selectedProduct);
		console.log(response);
	};

	$scope.onLookup = function(value) {
		$('#test_dlg').modal('show');
	};

	$scope.onCreate = function(value) {

		$http.get(base_url+'inventory/read/locations/')
		.success(function(data) {
			$scope.locations = data;
		});

		$('#create_product_dlg').modal('show');
	};


	$scope.do_inventory_search = function (search_str, no_timeout) {
		no_timeout = typeof no_timeout !== 'undefined' ? no_timeout : false;
		// ​​​ not empty string
		if (search_str)
		{
			$scope.isSearching = true;
			setTimeout(function() {
				var encoded_search_str = encodeURIComponent(search_str);
				// if the text has not changed, search =]
				if (search_str === $scope.search_products || no_timeout === true) {
					$http.get(base_url + 'inventory/read/' + $scope.query_type +'/' + encoded_search_str)
					.success(function(data) {
						// if it's empty show the no results alert
						$scope.no_results_found = jQuery.isEmptyObject(data);
						delete $scope.products;
						$scope.products = data;
						// finished searching
						$scope.isSearching = false;
					});
				}
			}, 500);
		} else {
			// nothing to show, so show the latest
			$scope.get_latest_inventory();
			// not even searching
			$scope.isSearching = false;
		}
	};

	$scope.$watch('search_products', $scope.do_inventory_search);


}])

.controller('inventoryQuickAddCtrl', ['$scope', '$http', '$timeout', '$filter', 'promiseTracker', function ($scope, $http, $timeout, $filter, promiseTracker) {

	// Set Templates
	var msg_template = {
		show: false,
		title: '',
		msg: ''
	};

	var addData_template = {
		upc: '',
		model: '',
		brand: '',
		desc: '',
		sku: '',
		qty: 1,
		location:'',
		price: 0,
		cost: 0,
		supplier: ''
	};

	// Set scope variables
	$scope.locations = {};

	$scope.alert_msg = msg_template;
	$scope.error_msg = msg_template;
	$scope.info_msg = msg_template;
	$scope.success_msg = msg_template;

	$scope.addData = addData_template;

	// move the focus to the UPC field
	$('#add_upc').focus();
	// select all on focus
	$('#add_upc').focus(function () { $(this).select(); } )
        .mouseup(function (e) {e.preventDefault(); });

    // load the locations:
    $http.get(base_url + 'inventory/read/locations').success(function (res) {
    	$scope.locations = res;
    });


    // Validate the SKU
	$scope.$watch('addData.sku', function (sku)
	{
	  setTimeout(function() {
	    if (sku === $scope.addData.sku && sku != '')
	    {
	    	// check if SKU is already taken
	      	$http.get(base_url + 'inventory/sku_exists/' + sku).success(function(res) {
	      		// bind the response to the error flag
	        	$scope.addForm.add_sku.$error.in_use = res.exists;
	      	});
	    }
	  }, 1000);
	});

	// Validate the UPC
	$scope.$watch('addData.upc', function (upc)
	{
	  setTimeout(function() {
	    if (upc === $scope.addData.upc && upc != '')
	    {
	    	// check if UPC is already taken
	      	$http.get(base_url + 'inventory/upc_exists/' + upc).success(function(res) {
	        	$scope.addForm.add_upc.$error.in_use = res.exists;
	      	});
	    }
	  }, 1000);
	});

	// auto fill form with UPC code
	$scope.getUPC = function (upc) {
		$http.get(base_url + 'util/upc/' + upc).success(function (res) {
				if(res.valid == 'true')
				{
					$scope.addData.upc = res.number;
					// decode html entitites outside of the DOM
					$scope.addData.desc = $('<div />').html(res.itemname).text();
					$scope.addData.price = (res.avg_price != '') ? currency(res.avg_price) : 0;

					// product lookup successful, now send focus to next field
					document.getElementById('add_model').focus();
				} else {
					$scope.alert_msg = {
						show: true,
						title: 'Alert!',
						msg: res.reason
					};
				}
			});
	};

	// function to submit the form after all validation has occurred
	$scope.submitAddForm = function(isValid) {
		// check to make sure the form is completely valid
		if (isValid) {
			$http.post(base_url + 'inventory/create', $scope.addData)
			.success(function(res){
				if (res.success) {
					// show success msg
					$scope.success_msg = {
						show: true,
						title: 'Success!',
						msg: res.msg
					};
					// clear values
					delete $scope.addData;
					$scope.addData = addData_template;
					// send focus to the first input
					$('#add_upc').focus();
				} else {
					// show error msg
					$scope.error_msg = {
						show:true,
						title: 'Error',
						msg:res.error_msg
					};
				}
			});
		}

	};

}]);
