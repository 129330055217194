angular.module('wt.authModule', [])

.controller('webtools-auth', ['$scope', '$http', 'authService' , function ($scope, $http, authService) {

	$scope.do_login = function(auth) {
		$('#wt-auth-login-btn').button('loading');


		$http.post(base_url + 'sessions/ajax_auth', auth).success(
			function(data, status)
			{
				if (data.loggedin) {
					$('#wt-auth-login-btn').button('Success!');
					$('#wt-auth-alert').html(data.response).addClass('alert alert-success');
					authService.loginConfirmed();
					$('#webtools-auth-dlg').modal('hide');
					$('#wt-auth-login-btn').button('reset');
					auth.password = '';
					$('#wt-auth-alert').html('');
					$('#wt-auth-alert').className = '';
				}
				else {
					$('#wt-auth-alert').html(data.response).addClass('alert alert-danger');
					$('#wt-auth-login-btn').button('reset');
				}

			});

		$('#wt-auth-login-btn').button('reset');
		$('#wt-auth-alert').html('');
		$('#wt-auth-alert').className = '';

	};

}]);
