angular.module('wt.ordersModule', [])
.controller('ordersCtrl',['$scope','$http','authService', function($scope, $http, authService){
	$scope.$on('event:auth-loginRequired', function() {
		$('#webtools-auth-dlg').modal('show');
	});

	// purchase order archetype
	$scope.po_m = {items:[],po_id:null,member_id:null,memo:''};
	$scope.po_item_input = {name:'',qty:1,unit_price:0};

	$scope.po_m_items_loading = false;

	// list of purchase orders
	$scope.po_list = {};

	// search box
	$scope.search_po_str = '';

	$scope.openPO = function(po_id) {
		// load the po into the po_m
		getPO(po_id);
		$scope.editPO();
	};

	$scope.editPO = function(po) {
		$scope.po_m = (typeof po == 'undefined' || po == false)  ? $scope.po_m : po;

		$scope.customer = {};

		// reset inputs and show modal
		$scope.po_item_input = {name:'',qty:1,unit_price:0};
		$('#edit_po_dlg').modal({show:true,modalOverflow:true});
		
	};

	$scope.deletePOm = function() {
		// check if we already have an id
		if ($scope.po_m.po_id !== null) {
			// if so, delte from database:
			$('#edit_po_dlg').modal('hide');
			confirm_dialog("you want to delete: " + $scope.po_m.po_id, "Are you sure?", function(id){
				$http.post(base_url + 'orders/delete/'+ id).success(function (res) {
					//console.log(res);
					getPO();
				});
			}, $scope.po_m.po_id, null, null);
		}
		// reset customer info
		$scope.customer = {};
		// reset the purchase order info
		$scope.po_m = {items:[],po_id:null,member_id:null,memo:''};
	};


	$scope.addtolist = function(item) {
		var total = (item.qty * item.unit_price);
		$scope.po_m.items.push({
			"name": (item.name)? item.name : '-',
			"qty": currency(item.qty),
			"unit_price": item.unit_price,
			"total_price": currency(total),
			"taxable":true
		});

		//$scope.calculate_totals();
		// reset input value
		$scope.po_item_input = {name:'',qty:1,unit_price:0};
 		
 		// scroll down to element
 		document.getElementById('po_item_inputs').scrollIntoView()

		$('#itemname').focus();
	}

	$scope.savePOm = function() {
		var po = $scope.po_m;
		// set the customer information
		po.member_id = $scope.customer.member_id;

		$http.post(base_url + 'orders/save', po).success(function (res) {
			//console.log(res);
		}).then(function(){
			$scope.po_m = {items:[],po_id:null,member_id:null,memo:''};
			$('#edit_po_dlg').modal('hide');
			// retrieve the po list
			getPO();
		});
	}


	$scope.toggletax = function(idx) {
		// toggles the taxed boolean
		$scope.po_m.items[idx].taxable = ($scope.po_m.items[idx].taxable) ? false : true;
		//$scope.calculate_totals();
	};

	$scope.rmFromList = function ( idx ) {
		$scope.po_m.items.splice(idx, 1);
		//$scope.calculate_totals();
	};

	function getPO(id) {
		if (typeof id == 'undefined' || id == false) {
			var url = base_url + 'orders/get';
			$http.get(url).success(function(res){
				$scope.po_list = res.data;
			});
		} else {
			var url = base_url + 'orders/get/' + id;
			$http.get(url).success(function(res){
				var items = [];
				// recasting of postgresql data types
				for (var i = res.data.items.length - 1; i >= 0; i--) {
					items[i] = {
						name: res.data.items[i].name,
						taxable: (res.data.items[i].taxable == 'f') ? false : true,
						total_price: parseFloat(res.data.items[i].total_price.replace(/[^0-9\.\-]/g, '')),
						unit_price: parseFloat(res.data.items[i].unit_price.replace(/[^0-9\.\-]/g, '')),
						qty: parseInt(res.data.items[i].qty, 10)
					};
				};
				// get rid of unvalid data (it's already saved)
				delete res.data.items;
				$scope.po_m = res.data;
				$scope.po_m.items = items;

				$scope.customer.member_id = res.data.member_id;
				// get member details
				if (res.data.member_id) {
					$http.get(base_url + 'reports/member_information/' + res.data.member_id)
					.success(function(res) {
						$scope.customer = {};
						$scope.customer.member_id = res.member_id;
						$scope.customer.data = res;
						$scope.customer.name = $scope.customer.data.first_name + " " + $scope.customer.data.last_name;
					});
				}
			});
		}
	}

	$scope.selectCustomerModal = function(){
		$scope.customer = {};
		$('#customer_data_modal').modal('show');

		// move the focus to the modal
		setTimeout(function(){$('#searchByCardID').focus();},500);
		// load up the latest members
		$scope.get_latest_members();
	};

	$scope.selectCustomer = function(customer) {
		$scope.customer = customer;
		var first_name = customer.first_name || customer.data.first_name;
		var last_name = customer.last_name || customer.data.last_name;
		var member_id = customer.member_id || customer.data.member_id;

		$scope.customer.name = first_name + " " + last_name;

		// get/refresh member details
		$http.get(base_url + 'reports/member_information/' + member_id)
		.success(function(res) {
			$scope.customer.data = res;
		});

		$('#customer_data_modal').modal('hide');
	};

	$scope.$watch('customerSearchStr', function (card_id) {
		// if not empty string
		if (card_id)
		{
			$scope.searchingCustomer = true;
			var encoded_card_id = encodeURIComponent(card_id);
			setTimeout(function() {
				// if the text has not changed, search =]
				if (card_id === $scope.customerSearchStr) {
					$scope.loading_latest_members = true;
					$http.get(base_url + 'members/read/search/' + encoded_card_id)
					.success(function(data) {
						// if it's empty show the no results alert	
						$scope.no_members_found = jQuery.isEmptyObject(data);
						
						// delete previous data
						delete $scope.members;

						$scope.members = data;
						// finished searching
						$scope.searchingCustomer = false;
					}).then(function(){
						$scope.loading_latest_members = false;
					});
				}
			}, 500);
		} else {
			// since we don't have anything to show:
			$scope.get_latest_members();
			
			// not even searching
			$scope.isSearching = false;
		}
	});

	$scope.get_latest_members = function() {
		$scope.loading_latest_members = true;
		$scope.members = {};
		$http.get(base_url + 'members/read/latest/10')
		.success(function(data) {
			$scope.members = data;
		}).then(function(){
			$scope.loading_latest_members = false;
		});
	};

	$scope.create_customer = function() {
		$('#customer_data_modal').modal('hide');
		$('#create_member').modal('show');
		$scope.new_membership = {
			customer_id:"",
			first_name:"",
			last_name:"",
			email:"",
			phone_number:"",
			membership_id:"",
			business_name:"",
			resale_number:""
		};
	};

	$scope.open_member = function ($event, member) {
		$event.stopPropagation();
		if (member.member_id != null) {
			$scope.loading_member_sales = true;
			$scope.member_sales_list = {};

			alert_message("Opening customer report file.", "success");

			$scope.customer = member;

			// get member details
			$http.get(base_url + 'reports/member_information/' + member.member_id)
			.success(function(res) {
				$scope.customer.data = res;
			});
			
			// open modal
			$('#member_sales_list_modal').modal('show');

			// get transaction data
			$http.get(base_url + 'reports/member_sales_list/' + member.member_id)
			.success(function(res) {
				$scope.member_sales_list = res;
			}).then(function(){
				$scope.loading_member_sales = false;
			});


		} else {
			alert_message("Member ID not found!.", "danger");
		}
	}

	$scope.submit_customer = function() {
		$('#create_customer_btn').button('loading');
		// submit fields
		$http.post(base_url + 'members/create', $scope.new_membership).success(function (res) {
			//console.log(res);
			if (res.status == "success") {
				alert_message(res.message, "success");
				
				// set customer data (data that exists on DB)
				$scope.selectCustomer(res);

				// close modal
				$('#create_member').modal('hide');
			} else {
				alert_message(res.message, "danger");
			}
		}).then(function(){
			$('#create_customer_btn').button('reset');
		});
		
	};

	$scope.$watch('search_po_str', function (search_str) {
		// if not empty string
		if (search_str)
		{
			$scope.isSearching = true;
			var encoded_search_str = encodeURIComponent(search_str);
			encoded_search_str = (encoded_search_str == 'undefined') ? '' : encoded_search_str;
			setTimeout(function() {
				// if the text has not changed, search =]
				if (search_str === $scope.search_po_str) {
					var url = base_url + 'orders/search_po/' + encoded_search_str;

					$http.get(url).success(function(res) {
						// if it's empty show the no results alert	
						$scope.no_results_found = jQuery.isEmptyObject(res.data);
						
						// delete previous data
						$scope.po_list = {};

						$scope.po_list = res.data;

					}).then(function(){
						// finished searching
						$scope.isSearching = false;
					});
				}
			}, 500);

		} else {
			// since we don't have anything to show:
			getPO();
			
			// not even searching
			$scope.isSearching = false;
		}
	});

	// autoload the po's
	getPO();

}]);