angular.module('wt.userModule', [])



.controller('user_page', ['$scope', '$http', '$timeout', '$filter', 'promiseTracker', function ($scope, $http, $timeout, $filter, promiseTracker)
{
	$scope.get_theme_list = function() {
		$http.get(base_url + 'users/get_themes').success(function (data) {
			$scope.theme_list = data;
		});
	};

	$scope.choose_theme = function(theme) {
		$http.post(base_url + 'users/select_theme/' + theme).success(function (data) {
			// data is a boolean
			if (data == "true") {
				alert_dialog("Theme updated!", "Success!", function(){location.reload(false);});
			} else {
				alert_dialog("Something went wrong! =/", "Uh Oh!");
			}
		});
	};

	// as soon as it loads
	$scope.get_theme_list();

}]);