angular.module('wt.posModule', ['ngSanitize', 'MassAutoComplete'])


.controller('POSCtrl', ['$q', '$scope','$http', '$compile', '$timeout', 'authService', 'promiseTracker', function($q, $scope, $http, $compile, $timeout, authService, promiseTracker) {
	$scope.$on('event:auth-loginRequired', function() {
		$('#webtools-auth-dlg').modal('show');
	});

	$scope.printer = function(text)
	{
		JFX.println(text);
		//console.log(text);
	};

	// activate tooltips
	$('[data-toggle="tooltip"]').tooltip();

	$scope.selectCustomerModal = function(){
		$scope.customer = {name:'',customer_id:''};
		$('#customer_data_modal').modal('show');

		// move the focus to the modal
		$timeout(function(){$('#searchByCardID').focus();},500);
		// load up the latest members
		$scope.get_latest_members();
	};

	$scope.selectCustomer = function(customer) {
		$scope.customer = {
			name: (customer.first_name + " " + customer.last_name),
			customer_id: customer.member_id
		};
		$('#customer_data_modal').modal('hide');
		$('#newitempopover').focus();
	};


	// Initialize with some values
	var autoItems = [{name:"T5 Toy AS-IS No Garantia", unit:5},
				{name:"T8 Toy AS-IS No Garantia", unit:8},
				{name:"T12 Toy AS-IS No Garantia", unit:12},
				{name:"T15 Toy AS-IS No Garantia", unit:15}
				];

	function suggestItemName(term) {
		// prep query for sending in url
		// /\W+/g .. allowed a-z 0-9~%.:_+-
		var q = encodeURIComponent(term.toLowerCase().replace(/[^A-Z0-9:_+-\s]+/ig, '').trim());

		// return promiseTracker
		return $http.get(base_url + 'inventory/read/all/' + q)
			.then(function(autoItems) {
				var results = [];
				var autoItem = {};

				for (var i = 0; i < autoItems.data.length && results.length < 10; i++) {
					autoItem = autoItems.data[i];

					if (autoItem.sku.toLowerCase().indexOf(q) === 0)
						results.push({
							obj: autoItem, 
							value: (autoItem.sku + ' ' + autoItem.retail_price),
							label: (autoItem.sku + ' ' + autoItem.retail_price)
						});
				}
				return results;
			});
	}

	$scope.autocompleteItem = {
		suggest: suggestItemName,
		on_select: function(s) {
			var d = {
				product_id: parseInt(s.obj.product_id),
				'name': s.obj.sku,
				unit: currency(s.obj.retail_price.replace(/[^0-9\.]/g, '')),
				taxed: (s.obj.taxed) ? true : false,
				qty: 1
			};
			$scope.newitem = d;
			$('#itemqty').val(1).focus().select();
		}
	};

	//$scope.$watch('newitem', function() {console.log("Change Detected: "); console.log($scope.newitem);}, true);

	$scope.$watch('customerSearchStr', function (card_id) {
		// if not empty string
		if (card_id)
		{
			$scope.searchingCustomer = true;
			var encoded_card_id = encodeURIComponent(card_id);
			$timeout(function() {
				// if the text has not changed, search =]
				if (card_id === $scope.customerSearchStr) {
					$scope.loading_latest_members = true;
					$http.get(base_url + 'members/read/search/' + encoded_card_id)
					.success(function(data) {
						// if it's empty show the no results alert
						$scope.no_members_found = jQuery.isEmptyObject(data);

						// delete previous data
						delete $scope.members;

						$scope.members = data;
						// finished searching
						$scope.searchingCustomer = false;
					}).then(function(){
						$scope.loading_latest_members = false;
					});
				}
			}, 500);
		} else {
			// since we don't have anything to show:
			$scope.get_latest_members();

			// not even searching
			$scope.isSearching = false;
		}
	});

	$scope.get_latest_members = function() {
		$scope.loading_latest_members = true;
		$scope.members = {};
		$http.get(base_url + 'members/read/latest/10')
		.success(function(data) {
			$scope.members = data;
		}).then(function(){
			$scope.loading_latest_members = false;
		});
	};

	$scope.create_customer = function() {
		$('#customer_data_modal').modal('hide');
		$('#create_member').modal('show');
		$scope.new_membership = {
			customer_id:"",
			first_name:"",
			last_name:"",
			email:"",
			phone_number:"",
			membership_id:"",
			business_name:"",
			resale_number:""
		};
	};

	$scope.submit_customer = function() {
		$('#create_customer_btn').button('loading');
		// submit fields
		$http.post(base_url + 'members/create', $scope.new_membership).success(function (res) {
			//console.log(res);
			if (res.status == "success") {
				alert_message(res.message, "success");
				// set customer data (data that exists on DB)
				$scope.customer = {
					name: (res.data.first_name + " " + res.data.last_name),
					customer_id: res.data.member_id
				};
				// close modal
				$('#create_member').modal('hide');

				// focus on next item
				$('#newitempopover').focus();

			} else {
				alert_message(res.message, "danger");
			}
		}).then(function(){
			$('#create_customer_btn').button('reset');
		});

	};

	$scope.testreceipt = function()
	{

		//console.log($scope.items);
		var date = new Date();
		$scope.customer = {name:'',customer_id:''};
		//JFX.testreceipt(JSON.stringify($scope.items));
		$scope.items[0] = {"name" : "Rockstar Energy", qty:10 , unit:10 , total: 100, taxed: true};
		$scope.items[1] = {"name" : "Glow Sticks", qty:10 , unit:10 , total: 100, taxed: false};

		$scope.header = {"date":date.toLocaleString(), "sale":"#1"};
		$scope.footer = {"total":"9.99","subtotal":"9.99", "tax":"0.00", "tendered":"9.99", "change":"0.00"};

		JFX.print_header(JSON.stringify($scope.header));

		// also clean the item names just in case:
		JFX.print_contents(JSON.stringify(cleanItemNames($scope.items)));

		JFX.print_footer(JSON.stringify($scope.footer));

	};

	$scope.update_pos_subtitle = function () {
			$http.get(base_url + 'sales/total_sales').success(function(data) {
			$scope.statusSubtitle = "has closed " + data + " sales";
		});
	};


	$scope.row_details = function () {

	};

	$scope.delete = function (idx) {
		$scope.items.splice(idx, 1);
		$scope.calculate_totals();
	};

	/** On submit to list **/
	$scope.addtolist = function () {
		// clean up the values
		var product_id = ($scope.newitem.product_id) ? $scope.newitem.product_id : false;
		var name = ($scope.newitem.name)? $scope.newitem.name : '-';
		var qty = (typeof $scope.newitem.qty == 'undefined' || isNaN($scope.newitem.qty))? 0 : $scope.newitem.qty;
		var unit = (typeof $scope.newitem.unit == 'undefined' || isNaN($scope.newitem.unit))? 0 : $scope.newitem.unit;
		var total = (qty * unit);

		var listItem = {
			"product_id": product_id,
			"name": name,
			"qty": currency(qty),
			"unit": currency(unit),
			"total": currency(total),
			"taxed":true
		};

		$scope.items.push(listItem);

		$scope.calculate_totals();
		
		// reset form
		$scope.newitem = {name:null, qty:1, unit:null, total:null, taxed:true};

		// deselect the qty if not already deselected
		document.getSelection().removeAllRanges();
		$('#itemqty').blur();
		$('#newitempopover').focus();

	};


	$scope.calculate_totals = function() {
		$scope.reset_totals();
		angular.forEach($scope.items, function(item,index) {
			this.receipt_subtotal += item.total;
			this.receipt_total_items += item.qty;
            this.total_taxable += ( (item.taxed) ? item.total : 0 );
		}, $scope);

		$scope.receipt_tax = ($scope.total_taxable * 0.0775);
		$scope.receipt_total = ($scope.receipt_subtotal + $scope.receipt_tax);
	};

	$scope.opendrawer = function() {
		if(isJFX()) {
		JFX.open_drawer();
		}
	};

	$scope.tax_exceptions = ['Retail','Wholesale','Labor','Warranty','Fee','Other'];


	$scope.toggletax = function(idx) {
		$scope.active_tax_ex = ($scope.items[idx].taxed) ? 'Retail' : $scope.items[idx].tax_exception;

		// show dialog
		$('#tax_dlg').modal('show');

		// save the current index
		$scope.active_idx = idx;
	};

	$scope.selecttax_ex = function(tax_ex) {
		// restore index
		var idx = $scope.active_idx;

		$scope.items[idx].tax_exception = tax_ex;
		$scope.active_tax_ex = tax_ex;

		// toggles the taxed boolean
		$scope.items[idx].taxed = (tax_ex == 'Retail') ? true : false;
		$scope.calculate_totals();

		$('#tax_dlg').modal('hide');
	};

	$scope.cancelsale = function () {
		$scope.items = {};
		$scope.customer = {name:'',customer_id:''};
		$scope.items = [];
		$scope.reset_totals();
		$scope.selectedpaytype = $scope.paytypes[0];
		$scope.newitem = {name:'', qty:1,unit:0,total:0,taxed:true};
		$scope.trans_num = 0;
		$scope.receipt_tendered = 0;
		$scope.receipt_change = 0;
	};

	$scope.reset_totals = function () {
		$scope.receipt_total = 0;
		$scope.receipt_subtotal = 0;
		$scope.receipt_tax = 0;
		$scope.receipt_total_items = 0;
		$scope.total_taxable = 0;
		$scope.receipt_change = 0;
	};

	$scope.processpayment = function() {
		$('#payment_btn').button('loading');

		var post_payment_data = {
			total: $scope.receipt_total,
			subtotal: $scope.receipt_subtotal,
			tendered: $scope.receipt_tendered,
			change: $scope.receipt_change,
			payment_type: $scope.selectedpaytype,
			total_tax: $scope.receipt_tax,
			total_taxable: $scope.total_taxable,
			currency: 'USD',
			tax_rate: '7.75',
			customer: $scope.customer,
			items: $scope.items
		};

		// console.log(post_payment_data);

		$http.post(base_url + 'sales/create_sale', post_payment_data).success(function (data) {
			//console.log(data);
			if (data.success) {
				alert_message("Sale <strong>#" + data.sale_id + "</strong> registered! Now Printing.", "success");
				$scope.opendrawer();
				$scope.print_receipt(data.sale_id);
			} else {
				alert_message("Something went wrong while processing the sale!", "danger");
			}
		}).then(function(res){
			$('#payment_btn').button('reset');
			// send email
			if (res.data.sale_id) {
				// if the sale id is set, trigger an email
				$http.get(base_url + 'sales/email_receipt/' + res.data.sale_id).success(
					function(res){
						if(res.status == 'success') {
							alert_message(res.message, "info");
						}
				});
			}
		});

	};

	$scope.print_receipt = function(sale_id)
	{
		var date = new Date();

		$scope.header = {"date": date.toLocaleString(),
		"sale":("#" + sale_id),
		"customer":$scope.customer
		};

		$scope.footer = {
			"total": currency($scope.receipt_total),
			"subtotal": currency($scope.receipt_subtotal),
			"tax": currency($scope.receipt_tax),
			"tendered":currency($scope.receipt_tendered),
			"change":currency($scope.receipt_change),
			"payment_type":$scope.selectedpaytype
		};

		var itemlist = cleanItemNames($scope.items);

		// send everything to printer!
		JFX.print_header(JSON.stringify($scope.header));
		JFX.print_contents(JSON.stringify(itemlist));
		JFX.print_footer(JSON.stringify($scope.footer));

		// not actually cancelling, just taking out the trash
		// (garbage collection)
		$('#payment_dlg').modal('hide');
		$scope.cancelsale();
		$scope.reset_totals();
	};

	$scope.completesale = function() {
		$('#payment_dlg').modal('show');
		$('#tendered_input').val('').focus();
		// send data to server
		// receive verification
		// print receipt
	};

	$scope.pushpull = function() {
		// open dialog
		$('#pushpull_dlg').modal('show');
		// enter amount
		$('#pushpull_dlg').on('shown', function(){
			$('#pushpull_input').val('').focus();
		});
	};

	$scope.do_pushpull = function(action) {
		var post_data = {action: action, amount: $scope.pushpull_amount, desc: $scope.pushpull_desc};

		var sendpushpull =  function () {
			$http.post(base_url + 'sales/pushpull', post_data).success(function (data) {
				if (data.success) {
					alert_message("Push/Pull Successfull!", "success");
				} else {
					alert_message("Something went wrong while doing the Push/Pull operation!", "danger");
				}
				$scope.pushpull_amount = 0;
				$('#pushpull_dlg').modal('hide');
			});
		}
		confirm_dialog("Are you sure you want to " + action + " " + $scope.pushpull_amount + "?", "Please Confirm", sendpushpull, post_data);
	};

	$scope.endofday = function() {
		$('body').modalmanager('loading');
		$http.post(base_url + 'sales/do_eod').success(function (data) {
			if (data.eod_id) {
				var report_url = base_url + "sales/get_eod/" + data.eod_id;
				alert_dialog("End Of Day Successful, check the report in the EOD Reports page. EOD ID: " + data.eod_id, "Success!");

				if(isJFX()) {
					JFX.open_in_browser(report_url + "?authtoken=" + getAuthToken());
				} else {
					window.open(report_url);
				}

			} else {
				alert_dialog("Something went wrong while making the report!, Maybe there is nothing to report!", "Uh Oh!");
			}
		}).then(function(){ $('body').modalmanager('loading'); });
	};


	$scope.update_register_totals = function() {
		// get: current: (cash, credit, debit, check)
		// total earned today

		//
	};

	$scope.do_inventory_search = function (search_str, no_timeout) {
		no_timeout = typeof no_timeout !== 'undefined' ? no_timeout : false;
		// if not empty string
		if (search_str && 0 !== search_str.length)
		{
			//$scope.isSearching = true;
			$timeout(function() {
				// if the text has not changed, search =]
				if (search_str === $scope.newitem.name || no_timeout === true) {
					var encoded_search_str = encodeURIComponent(search_str);
					$http.get(base_url + 'inventory/read/all/' + encoded_search_str)
					.success(function(data) {
						// if it's empty show the no results alert
						//$scope.no_results_found = jQuery.isEmptyObject(data);
						$('#newitempopover').popover('show');
						$scope.popoverItems = data;
						// finished searching
						//$scope.isSearching = false;
					});
				}
			}, 500);
		} else {
			// nothing to show, so show the latest
			//$scope.get_latest_inventory();
			// not even searching
			//$scope.isSearching = false;
			$('#newitempopover').popover('hide');
			//$scope.popoverItems = [];
		}
	};

	$scope.popoverselect = function (index) {
		alert(' ');
		console.log(index);
		//console.log($scope.popoverItems[index]);
		//$scope.popoverItems[index].selected = true;
	};

	$scope.paytypes = ['Cash', 'Credit', 'Debit', 'Check', 'Giftcard'];

    $scope.selectpaytype= function(paytype) {
       $scope.selectedpaytype = paytype;
       $('#tendered_input').focus();
    };

	$scope.calculate_change = function() {
		// hide and show are a workaround of a webview bug found in javafx 2.2
		$('#receipt_change').animate({opacity:0},0);
		$scope.receipt_change = currency(($scope.receipt_total - $scope.receipt_tendered) * -1);
		$('#receipt_change').animate({opacity:1},1);
	};

	$scope.openprint_dlg = function() {
		alert_dialog("This function is not yet available, it will come soon enough!", "Hold your horses!");
	};


	$scope.openpo_dlg = function() {
		// load the po_list
		getPO();
		$('#selectpo_dlg').modal('show');
	};

	$scope.load_po = function(po_id) {
		// load into the po_m
		var url = base_url + 'orders/get/' + po_id;
		$http.get(url).success(function(res){
			$scope.po_m = res.data;
			$scope.items = [];

			// get member details res.data
			$scope.customer = {
				name: 'Member ID: ' + $scope.po_m.member_id,
				customer_id: $scope.po_m.member_id
			};

			// set the items
			var item;
			for (var i = res.data.items.length - 1; i >= 0; i--) {
				item = res.data.items[i];

				$scope.items.push({
					"name": (item.name)? item.name : '-',
					"qty": item.qty,
					"unit": currency(item.unit_price.replace(/[^\d.]/g,'')),
					"total": currency(item.total_price.replace(/[^\d.]/g,'')),
					"taxed": (item.taxable == 't') ? true : false
				});
			};
			$scope.calculate_totals();

		}).then(function(){
			$('#selectpo_dlg').modal('hide');
		});
	};

	$scope.$watch('search_po_str', function (search_str) {
		// if not empty string
		if (search_str && 0 !== search_str.length)
		{
			$scope.isSearching = true;
			var encoded_search_str = encodeURIComponent(search_str);
			encoded_search_str = (encoded_search_str == 'undefined') ? '' : encoded_search_str;
			$timeout(function() {
				// if the text has not changed, search =]
				if (search_str === $scope.search_po_str) {
					var url = base_url + 'orders/search_po/' + encoded_search_str;

					$http.get(url).success(function(res) {
						// if it's empty show the no results alert
						$scope.no_results_found = jQuery.isEmptyObject(res.data);

						// delete previous data
						$scope.po_list = {};

						$scope.po_list = res.data;

					}).then(function(){
						// finished searching
						$scope.isSearching = false;
					});
				}
			}, 500);

		} else {
			// since we don't have anything to show:
			getPO();

			// not even searching
			$scope.isSearching = false;
		}
	});

	function getPO(id) {
		if (typeof id == 'undefined' || id == false) {
			var url = base_url + 'orders/get';
			$http.get(url).success(function(res){
				$scope.po_list = res.data;
			});
		} else {
			var url = base_url + 'orders/get/' + id;
			$http.get(url).success(function(res){
				$scope.po_m = res.data;
				// get member details
				if (res.data.member_id) {
					$http.get(base_url + 'reports/member_information/' + res.data.member_id)
					.success(function(res) {
						$scope.customer = {};
						$scope.customer.data = res;
						$scope.customer.name = $scope.customer.data.first_name + " " + $scope.customer.data.last_name;
					});
				}
			});
		}
	}

	$scope.openInventory = function () {
		$('#inventory_dlg').modal('show');

		// move the focus to the modal
		$timeout(function(){$('#inventorySearchBox').focus();},500);
	};

	$scope.selectProduct = function (product) {
		// hide modal
		$('#inventory_dlg').modal('hide');
		console.log(product);
		// insert product into receipt list
		var itemName = '';
		itemName = (product.brand) ? product.brand + ' ' : '';
		itemName += (product.model) ? product.model : '';
		//itemName = (itemName === '' && !product.desc) ? product.desc : itemName;
		itemName = (itemName === '')? 'Item #' + product.product_id : itemName;
		$scope.newitem.name = itemName;
		$scope.newitem.product_id = (product.product_id) ? product.product_id : false;
		// highlight the product bar
		// insert price (converting the string into numeric)
		$scope.newitem.unit = Number(product.retail_price.replace(/[^0-9\.]+/g,""))
		// focus on qty
		$('#itemqty').val(1).focus().select();
	};

	$scope.productsLoading = promiseTracker('products');

	$scope.get_latest_inventory = function () {
		$http.get(base_url + 'inventory/read/latest/10', {tracker: 'products'})
		.success(function(data) {
			// delete the prior
			delete $scope.inventoryList;
			// fill with the new
			$scope.inventoryList = data;
		});
	};

	$scope.do_inventory_search = function (search_str, no_timeout) {
		no_timeout = typeof no_timeout !== 'undefined' ? no_timeout : false;
		// ​​​ not empty string
		if (search_str)
		{
			$scope.isSearching = true;
			$timeout(function() {
				var encoded_search_str = encodeURIComponent(search_str);
				// if the text has not changed, search =]
				if (search_str === $scope.search_products || no_timeout === true) {
					$http.get(base_url + 'inventory/read/' + $scope.query_type +'/' + encoded_search_str)
					.success(function(data) {
						// if it's empty show the no results alert
						$scope.no_inventory_found = jQuery.isEmptyObject(data);
						delete $scope.inventoryList;
						$scope.inventoryList = data;
						// finished searching
						$scope.isSearching = false;
					});
				}
			}, 500);
		} else {
			// nothing to show, so show the latest
			$scope.get_latest_inventory();
			// not even searching
			$scope.isSearching = false;
		}
	};

	$scope.$watch('search_products', $scope.do_inventory_search);

	// call as soon as the page loads:
	$scope.get_latest_inventory();

  // we call cancelsale because it initializes everything back to normal
	$scope.cancelsale();

	// initialize our popover items object
	$scope.popoverItems = [];

	// inject and initialize the popover
	$scope.popover_html = "<div class=\"list-group\">\
			<a href=\"#\" class=\"list-group-item\" data-ng-repeat=\"item in popoverItems\" data-ng-class=\"{active: item.selected}\" data-ng-click=\"popoverselect($index)\">\
			{{item.sku}} = {{item.desc}}</a></div>";

	// $('#newitempopover').popover({
	// 	title:'<h4>Select Item</h4>',
	// 	placement:'top',
	// 	container: 'body',
	// 	html:true,
	// 	content: function () {return $compile($scope.popover_html)($scope);}
	// });

	$scope.itemKeyPress = function () {
		console.log(e);
	};

	// if is viewing a previous transaction
	$scope.trans_num = 0;

	$scope.prevtrans = function () {
		$http.get(base_url + 'sales/previous/' + $scope.trans_num).success(function(data) {
			console.log(data);
		});
	};

	$scope.nexttrans = function () {
		$http.get(base_url + 'sales/next/' + $scope.trans_num).success(function(data) {
			console.log(data);
		});
	};

	$scope.transdetails = function () {

	};

	$scope.items_are_loading = false;
	//$scope.$watch('newitem.name', $scope.do_inventory_search);

}]);
