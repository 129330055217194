angular.module('wt.graphModule', [])


.controller('graphCtrl', ['$scope', '$http', '$timeout', '$filter', 'promiseTracker', function ($scope, $http, $timeout, $filter, promiseTracker)
{

	$scope.chart = {
	    labels : [],
	    datasets : [
	        {
		        fillColor : "rgba(151,187,205,0.5)",
				strokeColor : "rgba(151,187,205,1)",
				pointColor : "rgba(151,187,205,1)",
				pointStrokeColor : "#fff",

	            // fillColor : "rgba(151,187,205,0)",
	            // strokeColor : "#e67e22",
	            // pointColor : "rgba(151,187,205,0)",
	            // pointStrokeColor : "#e67e22",
	            data : [0,0]
	        }
	    ], 
	    selected: {
	    	action:'',frame:'',grouping:''
	    }
	};


	var params = {
		action:
			[{label:"Total Sales", value:"sales_total"},
			{label: "Total Transactions", value: "sales_count"},
			{label:"Total Tax", value:"tax_total"}],

		group_by:
			[{label: "Day", value:"day"},
			{label:"Week", value:"week"},
			{label:"Month", value:"month"},
			{label:"Quarter", value:"quarter"},
			{label:"Year", value:"year"},
			{label:"Decade", value:"decade"}],

		limit: 100,
		frames:
			[{label:"Last Week", value:"last_week"},
			{label:"Last Month", value:"last_month"},
			{label:"Last Quarter", value:"last_quarter"},
			{label:"Last Year",value:"last_year"}],

		stores:[], //pos_registers.reg_location
		employee:[],//pos_sales.sold_by
		register:[]//pos_registers.register_id
};

	$scope.chart.params = params;

	$scope.chart_options = {
		// Boolean - Whether to animate the chart
		    animation: true,

		    // Number - Number of animation steps
		    animationSteps: 60,

		    // String - Animation easing effect
		    animationEasing: "easeOutQuart",

		    // Boolean - If we should show the scale at all
		    showScale: true,

		    // ** Required if scaleOverride is true **
		    // Number - The number of steps in a hard coded scale
		    scaleSteps: null,
		    // Number - The value jump in the hard coded scale
		    scaleStepWidth: null,
		    // Number - The scale starting value
		    scaleStartValue: null,

		    // String - Colour of the scale line
		    scaleLineColor: "rgba(0,0,0,.1)",

		    // Number - Pixel width of the scale line
		    scaleLineWidth: 1,

		    // Boolean - Whether to show labels on the scale
		    scaleShowLabels: true,

		    // Interpolated JS string - can access value
		    scaleLabel: "<%=value%>",

		    // Boolean - Whether the scale should stick to integers, not floats even if drawing space is there
		    scaleIntegersOnly: true,

		    // Boolean - Whether the scale should start at zero, or an order of magnitude down from the lowest value
		    scaleBeginAtZero: false,

		    // String - Scale label font declaration for the scale label
		    scaleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

		    // Number - Scale label font size in pixels
		    scaleFontSize: 12,

		    // String - Scale label font weight style
		    scaleFontStyle: "normal",

		    // String - Scale label font colour
		    scaleFontColor: "#666",

		    // Boolean - whether or not the chart should be responsive and resize when the browser does.
		    responsive: true,

		    // Boolean - Determines whether to draw tooltips on the canvas or not
		    showTooltips: true,

		    // Array - Array of string names to attach tooltip events
		    tooltipEvents: ["mousemove", "touchstart", "touchmove"],

		    // String - Tooltip background colour
		    tooltipFillColor: "rgba(0,0,0,0.8)",

		    // String - Tooltip label font declaration for the scale label
		    tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

		    // Number - Tooltip label font size in pixels
		    tooltipFontSize: 14,

		    // String - Tooltip font weight style
		    tooltipFontStyle: "normal",

		    // String - Tooltip label font colour
		    tooltipFontColor: "#fff",

		    // String - Tooltip title font declaration for the scale label
		    tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

		    // Number - Tooltip title font size in pixels
		    tooltipTitleFontSize: 14,

		    // String - Tooltip title font weight style
		    tooltipTitleFontStyle: "bold",

		    // String - Tooltip title font colour
		    tooltipTitleFontColor: "#fff",

		    // Number - pixel width of padding around tooltip text
		    tooltipYPadding: 6,

		    // Number - pixel width of padding around tooltip text
		    tooltipXPadding: 6,

		    // Number - Size of the caret on the tooltip
		    tooltipCaretSize: 8,

		    // Number - Pixel radius of the tooltip border
		    tooltipCornerRadius: 6,

		    // Number - Pixel offset from point x to tooltip edge
		    tooltipXOffset: 10,

		    // String - Template string for single tooltips
		    tooltipTemplate: "<%if (label){%><%=label%>: <%}%><%= value %>",

		    // String - Template string for single tooltips
		    multiTooltipTemplate: "<%= value %>",

		    // Function - Will fire on animation progression.
		    onAnimationProgress: function(){},

		    // Function - Will fire on animation completion.
		    onAnimationComplete: function(){}
	};




	var getChartData = function (params, old)
	{
		console.log(params);
		var url = base_url + 'graph';

		if (params.action === 'sales_total' || params.action === 'sales_count' || params.action === 'tax_total')
		{
			url = url + '/' + params.action;
		} else {
			url = url + '/sales_total';
		}

		if (params.frame === 'last_week' || params.frame === 'last_month' || params.frame === 'last_quarter' || params.frame === 'last_year')
		{
			url = url + '/time/' + params.frame;
		}
		if (params.grouping === 'day'||params.grouping === 'week'||params.grouping === 'month'||params.grouping === 'quarter'||params.grouping === 'year'||params.grouping === 'decade')
		{
			url = url + '/group_by/' + params.grouping;
		}

		function zip_chart(x, y) {
			// var x = ['a','b','c'];
			// var y = [1, 2, 3];
			// -> [['a', 1], ['b', 2], ['c', 3]]
			return x.map(function(c,i) {return [c,y[i]]});
		}

		$http.get(url).success(function (res) {
				$scope.chart.labels = [];
				$scope.chart.datasets[0].data = [];
				$scope.chart.labels = res.label;
				$scope.chart.datasets[0].data = res.data.map(parseFloat, 10);

				// for (var i = $scope.chart.datasets[0].data.length - 1; i >= 0; i--) {
				// 	console.log ($scope.chart.labels[i] + ', ' + $scope.chart.datasets[0].data[i]);
				// };

				console.table('getting data with: ' + url);
				//console.table(zip_chart($scope.chart.labels, $scope.chart.datasets[0].data));
				if (res.sql) {
					console.log('DB Query:');
					console.log(res.sql)
				}
			});
	}

	$scope.$watchCollection('chart.selected', getChartData);

	// $scope.chart = {
	//     labels : ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
	//     datasets : [
	//         {
	//             fillColor : "rgba(151,187,205,0)",
	//             strokeColor : "#e67e22",
	//             pointColor : "rgba(151,187,205,0)",
	//             pointStrokeColor : "#e67e22",
	//             data : [4, 3, 5, 4, 6]
	//         },
	//         {
	//             fillColor : "rgba(151,187,205,0)",
	//             strokeColor : "#f1c40f",
	//             pointColor : "rgba(151,187,205,0)",
	//             pointStrokeColor : "#f1c40f",
	//             data : [8, 3, 2, 5, 4]
	//         }
	//     ], 
	// };


}]);