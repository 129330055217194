angular.module('wt.eodModule', [])

.controller('eodReportsCtrl', ['$scope', '$http', '$timeout', '$filter', 'promiseTracker', function ($scope, $http, $timeout, $filter, promiseTracker)
{
	$scope.reports = {};

	$scope.load_reports = function () {
		$http.get(base_url + 'reports/eod_list').success(function (data) {
			$scope.reports = data;
		});
	};

	$scope.open_eod = function (eod_id) {
		// sales/get_eod/<eod_id>
		if(isJFX()) {
			JFX.open_in_browser(base_url + 'sales/get_eod/' + eod_id + "?authtoken=" + getAuthToken());
		} else {
			var win = window.open(base_url + 'sales/get_eod/' + eod_id);
		}
	};

	// fire on load!
	$scope.load_reports();
	
}]);