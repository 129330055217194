angular.module('wt', ['http-auth-interceptor',
  'ajoslin.promise-tracker',
  'angles',
  //'nvd3ChartDirectives',
  'nvd3',
  'ngBootstrap',
  'angucomplete',
  'wt.filters',
  'wt.services',
  'wt.directives',

  // controllers:
  'wt.eodModule',
  'wt.graphModule',
  'wt.inventoryModule',
  'wt.membersModule',
  'wt.ordersModule',
  'wt.posModule',
  'wt.reportsModule',
  'wt.settingsModule',
  'wt.shareModule',
  'wt.timesheetModule',
  'wt.userModule',
  'wt.userReportsModule',
  'wt.authModule',
  'wt.dashboardModule'
  ])

// enable  X-Requested-With header
.config(['$httpProvider', function($httpProvider) {
    $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
}]);
