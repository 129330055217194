/** Checks for JavaFX interface**/
function isJFX()
{
  if (typeof JFX == "undefined" || typeof JFX.return_true == "undefined" ) {
    return false;
  } else {
    return JFX.return_true();
  }
}

$(function(){
  // Initialize autoHidingNavbar plugin
  $('nav#wt-navigator').autoHidingNavbar();

  // setup for javafx webview
  if (!isJFX())
  {
    var to_console = function (text) {
      console.log(text);
    };

    window.JFX = {
      console_shim: true,
      print_header:to_console,
      print_contents:to_console,
      println:to_console,
      print_footer:to_console,
      testreceipt:to_console,
      open_in_browser:to_console
    }
  }

  // select-all class handler
  // only works if input already exists at page load
  $('input.select-all').on('focus', function (e) {
      $(this).one('mouseup', function () {
        $(this).select();
        return false;
      }).select();
  });

  // Safari fix for mobile devices
  $('[data-toggle=dropdown]').each(function() {
   this.addEventListener('click', function() {}, false);
  });

  // Initialize Tooltips
  $("body").tooltip({ selector: '[data-toggle="tooltip"]' });
});


function jumpToAnchor(h) {
  // Save down the URL without hash.
  var url = location.href;
  // Go to the target element.
  location.href = '#'+h;
  // Don't like hashes. Changing it back.
  history.replaceState(null, null, url);
}

function roundNumber(number, digits) {
            var multiple = Math.pow(10, digits);
            var rndedNum = Math.round(number * multiple) / multiple;
            return rndedNum;
}

/* rounds up =] */
function currency(money) {
  var out = (typeof money == "undefined" || isNaN(money)) ? 0 : money;
    out = (money == null) ? 0 : money;
    out = parseFloat(out);
  return parseFloat(roundNumber(out, 2));
}

function getAuthToken() {
  var xmlhttp = new XMLHttpRequest();
  xmlhttp.onreadystatechange = function() {};
  // the false param makes the request synchronous (blocking)
  xmlhttp.open("GET", base_url + "sessions/get_token", false);
  xmlhttp.send();
  return xmlhttp.responseText;
}

//pads left
String.prototype.lpad = function(padString, length)
{
  var str = this;
    while (str.length < length)
        str = padString + str;
    return str;
}

//pads right
String.prototype.rpad = function(padString, length)
{
  var str = this;
    while (str.length < length)
        str = str + padString;
    return str;
}

// cleans the name and sets it to the appropiate size for
// printing in the receipt
function cleanItemNames(items)
{
  var space = 10;

  for(var i=0, len=items.length; i < len; i++)
  {
    var item_len = items[i].name.length;

    if (item_len > space)
    {
      items[i].name = items[i].name.substring(0, space);
    }

    if (item_len < space)
    {
      while (items[i].name.length < space)
      items[i].name = items[i].name + " ";
    }

    // little more padding for everyone!
    items[i].name = items[i].name + "  ";
  }
  return items;
}

/**
 * Selects all chars in an input field when clicked
 * use as: <input value="my text" onclick="select_all(this)" name="url" type="text" />
 * @param  DOM obj text input area
 */
function select_all(obj) {
    var text_val=eval(obj);
    text_val.focus();
    text_val.select();
    if (!document.all) return; // IE only
    r = text_val.createTextRange();
    r.execCommand('copy');
}

/**
 * removes a set of characters
 * @param  int index     Position of the character to remove from a string
 * @param  int charcount Number of characters to remove from the string starting
 *                       from the index position
 * @return string
 */
String.prototype.removeAt = function(index, charcount) {
  return this.substr(0, index) + this.substr(index + charcount);
};


/* formats a phone number correctly */
function format_phone(phone) {
	// remove all non numeric
	phone = phone.replace(/[^0-9]+/g, '');

	switch (phone.length)
	{
		case 10:
		// mach 6641254900 to 664-125-4900
		phone = phone.replace(/(\d{3})(\d{3})(\d{4})/g, '$1-$2-$3');
		break;

		case 11:
		// match 16199428191 to +1 619-428-9191
		phone = phone.replace(/[1](\d{3})(\d{3})(\d{4})/g, '+1 $1-$2-$3');
		break;

		case 12:
		// match 526641254900 to +52 664-125-4900
		phone = phone.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/g, '+$1 $2-$3-$4');
		break;

		case 13:
		// match 5216641254900 to +521 664-125-4900
		phone = phone.replace(/(\d{3})(\d{3})(\d{3})(\d{4})/g, '+$1 $2-$3-$4');
		break;

		default:
		// Debug feature
    // console.log("Invalid: " + phone);
		// phone = ("Invalid: " + phone);
		break;
	}

	return phone;
}

// Cleans the membership ids of all non numeric values
function clean_membership ()
{
	var input = $(this),
      cache = input.val(),
      start = this.selectionStart,
      end = this.selectionEnd,
      text = input.val().replace(/[^0-9]+/g, ''),
      removed = cache.length - text.length;

  // Only modify if a change was made
  if (cache != text) {
    input.val(text);
    // restore cursor position
    this.setSelectionRange(start - removed, end - removed);
  }
}

// Launders money
// and also rewrites your input so you can't type in a non-number
function clean_money ()
{
  var input = $(this),
      text = input.val(),
      cache = text,
      start = this.selectionStart,
      end = this.selectionEnd,
      removed = 0,
      money = 0;

  // only allow numbers and decimal point
  text = text.replace(/[^0-9\.]/g, '');
  removed += cache.length - text.length;

  // Check for decimal point
  var dec_pnt = text.indexOf('.');

  if(dec_pnt != -1) {

    // remove all next instances of decimal point:
    var next_dec_pnt = text.indexOf('.', (dec_pnt + 1));

    while (dec_pnt < next_dec_pnt) {
      text = text.removeAt(next_dec_pnt, 1);
      next_dec_pnt = text.indexOf('.', (dec_pnt + 1));
      removed += 1;
    }
    // if a greater decimal precision of 2 dec. digits
    if ( (text.length - dec_pnt) > 2 ) {
      money = parseFloat(text);
      // round to two digits
      text = Math.round(money*100) / 100;
    }
  }

  if (cache !== text) {
    input.val(text);
    // restore cursor position
    this.setSelectionRange(start - removed, end - removed);
  }
}


function create_dom_fragment(htmlStr) {
    var frag = document.createDocumentFragment(),
        temp = document.createElement('div');
    temp.innerHTML = htmlStr;
    while (temp.firstChild) {
        frag.appendChild(temp.firstChild);
    }
    return frag;
}

function alert_message(msg, msg_class)
{
  // generate unique id, later used to close the div
  var fragment_id = "div_" + new Date().getTime().toString();
  // create the fragment
  var fragment = create_dom_fragment('<div id="'+fragment_id+'" class="alert alert-'+msg_class+' floatmsg alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>'+msg+'</div>');
  // attach fragment to DOM
  document.body.insertBefore(fragment, document.body.childNodes[0]);

  // view for 4 seconds, then remove from DOM
  setTimeout(function() {
    $('#'+fragment_id).fadeTo(1000, 0).slideUp(1000, function(){
        $(this).remove();
    });
  }, 4500);
}


/**
 * Opens an alert dialog using jQueryUI
 * @param  string output_msg The message shown inside the dialog
 * @param  string title_msg The dialog box title
 * @param  function callback an optional callback
 * @return void
 */
function alert_dialog(output_msg, title_msg, callback)
{
  // Debugging feature:
  //alert("caller is " + arguments.callee.caller.toString());

  // Height alert fix for mobile devices (dialog height in percentage)
  var alert_dialog_height_pixels = windowPercent(50, 'y');

  // Not everytime the alert_dialog height looks good with a percentage
  alert_dialog_height_pixels = (output_msg.length > 800) ? alert_dialog_height_pixels : 'auto';


    title_msg = (title_msg) ? title_msg : 'Alert';

    output_msg = (output_msg) ? output_msg : 'No Message to Display.';

    $("<div></div>").html(output_msg).dialog({
        title: title_msg,
        resizable: false,
        width: windowPercent(60, 'x'),
        height: alert_dialog_height_pixels,
        buttons:
        {
          "ok":
          {
            text: "Ok",
            class: "btn btn-default",
            id: "submit_btn",
            click: function ()
            {
              $(this).dialog("close");
              // If a callback was defined, call it back!
              if (typeof callback == 'function') {
                callback();
              }
            }
          }
        }//,
        // open: function(event, ui)
        // {
        //   // save previous scrolling status
        //   alert_dialog.was_body_scrolling = $('body').css('overflow');
        //   // disable background scrolling
        //   $("body").css({ overflow: 'hidden' });
        // },
        // beforeClose: function(event, ui)
        // {
        //   // push the previous scrolling status
        //   $("body").css({ overflow: alert_dialog.was_body_scrolling });
        // }
    });
}
// END ALERT DIALOG

/**
 * Confirms a message to the user, if ok, calls the callback
 * @param  string   message  dialog message
 * @param  string   title    dialog title
 * @param  function callback the function to be called if the message is confirmed
 * @param  object   param    callback function parameter object
 * @return void
 */
function confirm_dialog(message, title, callback1, param1, callback2, param2)
{
  var dlg_height = windowPercent(50, 'y');

  // Not everytime the alert_dialog height looks good with a percentage
  dlg_height = (message.length > 800) ? dlg_height : 'auto';


    title = (title) ? title : 'Alert';

    message = (message) ? message : 'Please confirm.';

    $("<div></div>").html(message).dialog({
        title: title,
        resizable: false,
        width: windowPercent(60, 'x'),
        height: dlg_height,
        buttons:
        {
          "ok":
          {
            text: "Ok",
            class: "btn btn-danger",
            id: "confirm_ok_btn",
            click: function ()
            {
              $(this).dialog("close");
              // If a callback was defined, call it back!
              if (typeof callback1 == 'function') {
                callback1(param1);
              }
            }
          },
          "cancel":
          {
            text: "Cancel",
            class: "btn btn-default",
            id: "confirm_cancel_btn",
            click: function ()
            {
              $(this).dialog("close");
              if (typeof callback2 == 'function') {
                callback2(param2);
              }
            }
          }
        }//,
        // open: function(event, ui)
        // {
        //   // save previous scrolling status
        //   confirm_dialog.was_body_scrolling = $('body').css('overflow');
        //   // disable background scrolling
        //   $("body").css({ overflow: 'hidden' });
        // },
        // beforeClose: function(event, ui)
        // {
        //   // push the previous scrolling status
        //   $("body").css({ overflow: confirm_dialog.was_body_scrolling });
        // }
    });
}


// Boostrap input span prepend/append width fix
jQuery(function($) {
  $(window).resize(function() {
    $(".input-prepend, .input-append").each(function(index, group) {
      var input = $("input", group).css('width', '');
      $(".add-on, .btn", group).each(function() {
        input.css('width', '-=' + $(this).outerWidth());
      });
    });
  }).trigger('resize');
});

// Returns the pixels in the axis and percent paramenters axis[x,y]
function windowPercent (percent, axis) {
  switch (axis) {
    case 'x':
      return ( $(window).width() / 100 * percent );

    case 'y':
      return ( $(window).height() / 100 * percent );

    default:
      console.log("Error in windowPercent parameters, set windowPercent() axis parameter");
      return 0;
  }
}


/* TODO: move this function out of here */
function getProducData(upc) {
  var URL = 'http://csrv.jcpoweraudioshop.com/JCPVIP/util/upc';
  $.ajax({
    url: URL,
    type: 'POST',
    data: {'upc' : upc},
    success: function (data)
    {
      console.log(data);
    },
    error: function (error_msg)
    {
      console.log(error_msg);
    }
  });
}

// if ISO dates are not supported by the browser:
if ( !Date.prototype.toISOString ) {
  ( function() {

    function pad(number) {
      var r = String(number);
      if ( r.length === 1 ) {
        r = '0' + r;
      }
      return r;
    }

    Date.prototype.toISOString = function() {
      return this.getUTCFullYear()
        + '-' + pad( this.getUTCMonth() + 1 )
        + '-' + pad( this.getUTCDate() )
        + 'T' + pad( this.getUTCHours() )
        + ':' + pad( this.getUTCMinutes() )
        + ':' + pad( this.getUTCSeconds() )
        + '.' + String( (this.getUTCMilliseconds()/1000).toFixed(3) ).slice( 2, 5 )
        + 'Z';
    };

  }() );
}

(function($) {

$(document)
    .on( 'hidden.bs.modal', '.modal', function() {
        $(document.body).removeClass( 'modal-scrollbar' );
    })
    .on( 'show.bs.modal', '.modal', function() {
        // Bootstrap's .modal-open class hides any scrollbar on the body,
        // so if there IS a scrollbar on the body at modal open time, then
        // add a right margin to take its place.
        if ( $(window).height() < $(document).height() ) {
            $(document.body).addClass( 'modal-scrollbar' );
        }
    });

})(window.jQuery);

function array_pad(input, pad_size, pad_value) {
  //  discuss at: http://phpjs.org/functions/array_pad/
  // original by: Waldo Malqui Silva
  //   example 1: array_pad([ 7, 8, 9 ], 2, 'a');
  //   returns 1: [ 7, 8, 9]
  //   example 2: array_pad([ 7, 8, 9 ], 5, 'a');
  //   returns 2: [ 7, 8, 9, 'a', 'a']
  //   example 3: array_pad([ 7, 8, 9 ], 5, 2);
  //   returns 3: [ 7, 8, 9, 2, 2]
  //   example 4: array_pad([ 7, 8, 9 ], -5, 'a');
  //   returns 4: [ 'a', 'a', 7, 8, 9 ]

  var pad = [],
    newArray = [],
    newLength,
    diff = 0,
    i = 0;

  if (Object.prototype.toString.call(input) === '[object Array]' && !isNaN(pad_size)) {
    newLength = ((pad_size < 0) ? (pad_size * -1) : pad_size);
    diff = newLength - input.length;

    if (diff > 0) {
      for (i = 0; i < diff; i++) {
        newArray[i] = pad_value;
      }
      pad = ((pad_size < 0) ? newArray.concat(input) : input.concat(newArray));
    } else {
      pad = input;
    }
  }

  return pad;
}

function SHA1(msg) {

  function rotate_left(n,s) {
    var t4 = ( n<<s ) | (n>>>(32-s));
    return t4;
  };

  function lsb_hex(val) {
    var str="";
    var i, vh, vl;
    for( i=0; i<=6; i+=2 ) {
      vh = (val>>>(i*4+4))&0x0f;
      vl = (val>>>(i*4))&0x0f;
      str += vh.toString(16) + vl.toString(16);
    }
    return str;
  };

  function cvt_hex(val) {
    var str="";
    var i, v;
    for( i=7; i>=0; i-- ) {
      v = (val>>>(i*4))&0x0f;
      str += v.toString(16);
    }
    return str;
  };


  function Utf8Encode(string) {
    string = string.replace(/\r\n/g,"\n");
    var utftext = "";
    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n);
      if (c < 128) {
        utftext += String.fromCharCode(c);
      }
      else if((c > 127) && (c < 2048)) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      }
      else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    return utftext;
  };

  var blockstart;
  var i, j;
  var W = new Array(80);
  var H0 = 0x67452301;
  var H1 = 0xEFCDAB89;
  var H2 = 0x98BADCFE;
  var H3 = 0x10325476;
  var H4 = 0xC3D2E1F0;
  var A, B, C, D, E;
  var temp;
  msg = Utf8Encode(msg);
  var msg_len = msg.length;

  var word_array = new Array();
  for( i=0; i<msg_len-3; i+=4 ) {
    j = msg.charCodeAt(i)<<24 | msg.charCodeAt(i+1)<<16 |
    msg.charCodeAt(i+2)<<8 | msg.charCodeAt(i+3);
    word_array.push( j );
  }

  switch( msg_len % 4 ) {
    case 0:
      i = 0x080000000;
      break;
    case 1:
      i = msg.charCodeAt(msg_len-1)<<24 | 0x0800000;
      break;
    case 2:
      i = msg.charCodeAt(msg_len-2)<<24 | msg.charCodeAt(msg_len-1)<<16 | 0x08000;
      break;
    case 3:
      i = msg.charCodeAt(msg_len-3)<<24 | msg.charCodeAt(msg_len-2)<<16 | msg.charCodeAt(msg_len-1)<<8    | 0x80;
      break;
  }
  word_array.push(i);
  while((word_array.length % 16) != 14 ) word_array.push(0);
  word_array.push(msg_len>>>29);
  word_array.push((msg_len<<3)&0x0ffffffff);
  for ( blockstart=0; blockstart<word_array.length; blockstart+=16 ) {
    for( i=0; i<16; i++ ) W[i] = word_array[blockstart+i];
    for( i=16; i<=79; i++ ) W[i] = rotate_left(W[i-3] ^ W[i-8] ^ W[i-14] ^ W[i-16], 1);
    A = H0;
    B = H1;
    C = H2;
    D = H3;
    E = H4;
    for( i= 0; i<=19; i++ ) {
      temp = (rotate_left(A,5) + ((B&C) | (~B&D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B,30);
      B = A;
      A = temp;
    }
    for( i=20; i<=39; i++ ) {
      temp = (rotate_left(A,5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B,30);
      B = A;
      A = temp;
    }
    for( i=40; i<=59; i++ ) {
      temp = (rotate_left(A,5) + ((B&C) | (B&D) | (C&D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B,30);
      B = A;
      A = temp;
    }
    for( i=60; i<=79; i++ ) {
      temp = (rotate_left(A,5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B,30);
      B = A;
      A = temp;
    }
    H0 = (H0 + A) & 0x0ffffffff;
    H1 = (H1 + B) & 0x0ffffffff;
    H2 = (H2 + C) & 0x0ffffffff;
    H3 = (H3 + D) & 0x0ffffffff;
    H4 = (H4 + E) & 0x0ffffffff;
  }
  var temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);
  return temp.toLowerCase();
}
