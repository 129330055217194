angular.module('wt.timesheetModule', [])

.controller('TimesheetCtrl', ['$scope', '$http', 'authService', '$filter', function ($scope, $http, authService, $filter) {
	$scope.$on('event:auth-loginRequired', function() {
		$('#webtools-auth-dlg').modal('show');
	});

	$scope.update_timesheet_subtitle = function () {
			$http.get(base_url + 'timesheet/read/now_logged_in').success(function(data) {
			$scope.statusSubtitle = (data == 1)? "has " + data + " logged in user" : "has " + data + " logged in users";
		});
	};


	$scope.update_timesheet_table = function () {
		$scope.timesheetLoading = true; // show loading bars
		$http.get(base_url+'timesheet/read/user')
		.success(function(data) {
			$scope.timesheetLoading = false;
			$scope.timesheet = data;
			$scope.update_current_state();
			$scope.update_timesheet_subtitle();
		});
	};

	$scope.check_details = function(check) {
		delete $scope.selectedCheck;
		$scope.selectedCheck = check;
		$('#check_details_dlg').modal('show');
	};

	$scope.check_timesheet = function(in_out ,user) {
		var btn = (in_out == 'check_in') ? $('#check_in_btn') : $('#check_out_btn');
		// load
		btn.button('loading');

		// if not defined, empty string
		user = typeof user !== 'undefined' ? user : '';
		$http.post(base_url + 'timesheet/create/'+in_out, user).success(function (data) {
			$scope.update_timesheet_table();
			// reset the loading button
			btn.button('reset');
			alert_dialog(data.msg, data.msg_title);
		});


	};

	$scope.update_current_state = function() {
		$http.get(base_url+'timesheet/read/current_state')
		.success(function(data) {
			$scope.current_state = (data.hasOwnProperty('func')) ? data.func : 'OUT' ;

			switch ($scope.current_state)
			{
				case 'IN':
				$('#check_in_btn').removeClass('btn-primary').addClass('btn-default disabled');
				$('#check_out_btn').removeClass('btn-default disabled').addClass('btn-primary');
				break;

				case 'OUT':
				$('#check_in_btn').removeClass('btn-default disabled').addClass('btn-primary');
				$('#check_out_btn').removeClass('btn-primary').addClass('btn-default disabled');
				break;
			}
		});
	};

	// call as soon as the page loads:
	$scope.update_timesheet_table();

}])

.controller('TimesheetOverviewCtrl', ['$scope', '$http', 'authService', '$filter', function ($scope, $http, authService, $filter) {
	$scope.$on('event:auth-loginRequired', function() {
		$('#webtools-auth-dlg').modal('show');
	});

	$scope.user_list = [];


	$scope.loadTimesheet = function (user) {
		var timesheetURL = base_url;
		// test case for all users
		if (user === false) {
			$scope.timesheetMsg = 'All Users';
			timesheetURL = timesheetURL + 'timesheet/read/all_users/30'
		} else {
			$scope.timesheetMsg = user.uname;
			timesheetURL = timesheetURL + 'timesheet/read/user/' + user.username_login + '/30';
		}

		$scope.timesheetLoading = true; // show loading bars
		$http.get(timesheetURL).success(function(data) {
			$scope.timesheetLoading = false;
			$scope.timesheet = data;
		});
	}

	$scope.check_details = function(check) {
		delete $scope.selectedCheck;
		$scope.selectedCheck = check;
		$('#check_details_dlg').modal('show');
	};


	function getUserList () {
		$http.get(base_url + 'users/get_user_list').success(function(res) {
			$scope.user_list = res;
		});
	}

	$scope.loadTimesheet(false);
	getUserList();


}]);
